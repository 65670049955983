import PropTypes from 'prop-types';
import { CommentOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { ModalSpecificDemand } from './ModalSpecificDemand';
import ClaimConversation from '../../../components/ClaimConversation';
import { useConversationContext } from '../../../../contexts/ConversationContext';
import { useAuthContext } from '../../../../contexts/AuthContext';

const iconSize = 18;

/**
 * Renders a button component for messaging related functionality.
 *
 * @component
 * @param {Object} productClaim - The product claim object.
 * @returns {JSX.Element} The rendered MessagerieButton component.
 */
const MessagingButton = ({ productClaim }) => {
  const initialState = { modal: false, conversation: false };
  const [open, setOpen] = useState(initialState);
  const onCancel = () => setOpen(false);

  const {
    setSelectedConversation,
    selectedConversation,
    patchToReadConversation,
    refreshDrawer,
    setRefreshDrawer
  } = useConversationContext();

  const { dispatchAPI, company } = useAuthContext();

  const fetchConversation = async () => {
    const claimMessageUrl = `/conversations/claim?company=${company}&claim_id=${productClaim?._id}`;

    const { data } = await dispatchAPI('GET', { url: claimMessageUrl });

    const isEmptyObject = (obj) => Object.keys(obj).length === 0;
    if (isEmptyObject(data)) {
      return setOpen({ modal: true });
    }
    setSelectedConversation(data[0]);
    return setOpen({ conversation: true });
  };

  const onClick = async () => {
    await fetchConversation();
  };

  useEffect(() => {
    if (refreshDrawer) {
      if (selectedConversation?.claim_id === productClaim?._id) {
        fetchConversation();
        setRefreshDrawer(false);
      }
    }
  }, [refreshDrawer]);

  return (
    <>
      <CommentOutlined onClick={onClick} style={{ fontSize: iconSize }} />
      <ModalSpecificDemand
        asset={productClaim}
        open={open.modal}
        onCancel={onCancel}
      />
      <ClaimConversation
        selectedConversation={selectedConversation}
        open={open.conversation}
        setOpen={setOpen}
        patchToReadConversation={patchToReadConversation}
        productClaim={productClaim}
        selectRefresh="drawer"
      />
    </>
  );
};

export default MessagingButton;

MessagingButton.propTypes = {
  productClaim: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
