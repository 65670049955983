import PropTypes from 'prop-types';
import { useState } from 'react';
import { Modal, Button, Form, Input, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatProgToClaimModel } from '../../../../utils/formatters/formatProgToClaimModel';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { TextArea } = Input;

/**
 * @component
 * @name ModalSpecificDemand
 * @description A component that displays a modal to start a new conversation
 * @param {boolean} open The state of the modal
 * @param {function} onCancel The function to close the modal
 * @returns {jsx} jsx - ModalSpecificDemand component
 */
export const ModalSpecificDemand = ({ asset, open, onCancel }) => {
  const { t } = useTranslation();
  const { dispatchAPI, company, user } = useAuthContext();
  const { _id } = user;
  let sitesIds;
  const {
    unit_counter,
    programme_types,
    civil_liability_sub_programmes,
    construction_sub_programmes,
    transport_sub_programmes,
    site
  } = asset;

  const entitiesIds = Array.isArray(asset?.entity)
    ? asset?.entity.map((ent) => ent?._id)
    : asset?.entity?._id;

  if (site) {
    sitesIds = Array.isArray(site) ? site.map((s) => s._id) : [site._id];
  }

  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const body = {
        ...values,
        from: _id,
        initiated_by: _id,
        claim_id: asset._id,
        unit_counter,
        category: 'CLAIM',
        company,
        programme_types,
        civil_liability_sub_programmes,
        construction_sub_programmes,
        transport_sub_programmes,
        fleet_sub_programmes: asset.fleet_type,
        entity: entitiesIds,
        site: sitesIds,
        ref_claim_model: formatProgToClaimModel(programme_types)
      };

      await dispatchAPI('POST', {
        url: '/conversations/claims',
        body
      });

      form.resetFields();
      onCancel();
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('messages.titles.start_new_conversation')}
      width={640}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Flex vertical gap="middle">
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="claim_id" label={t('messages.form.claim_number')}>
            <Input disabled placeholder={asset.unit_counter} />
          </Form.Item>
          <Form.Item
            name="content"
            rules={[
              {
                required: true,
                message: t('messages.errors.missing_content')
              }
            ]}
          >
            <TextArea style={{ resize: 'none' }} rows={8} />
          </Form.Item>
          <Flex justify="flex-end" gap="small">
            <Button
              onClick={() => {
                onCancel();
                form.resetFields();
              }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('buttons.send')}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Modal>
  );
};

ModalSpecificDemand.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  asset: PropTypes.shape({
    _id: PropTypes.string,
    unit_counter: PropTypes.string,
    fleet_type: PropTypes.string,
    programme_types: PropTypes.string,
    civil_liability_sub_programmes: PropTypes.string,
    construction_sub_programmes: PropTypes.string,
    transport_sub_programmes: PropTypes.string,
    entity: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    site: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  })
};

ModalSpecificDemand.defaultProps = {
  open: false,
  onCancel: () => {},
  asset: {
    _id: '',
    unit_counter: '',
    fleet_type: '',
    programme_types: '',
    civil_liability_sub_programmes: '',
    construction_sub_programmes: '',
    transport_sub_programmes: '',
    entity: '',
    site: ''
  }
};
