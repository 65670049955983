// TODO: Wait for client feedback. They do not want the option to delete claims and specific demands, so the delete-related code is commented out.
import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MessagingButton from './utils/renderClaimButtons/MessagerieButton';

const iconSize = 18;

/**
 * Creates an action column configuration for a table, including various icons and links.
 *
 * @function
 * @param {string} actionColumnUrl - The URL for the action column.
 * @param {string} deleteUrl - The URL for the delete action.
 * @returns {Array} An array representing the action column configuration.
 */

export const useActionColumn = (
  actionColumnUrl
  //  deleteUrl
) => {
  const { t } = useTranslation();

  // const { user, dispatchAPI } = useAuthContext();

  // const { t } = useTranslation();
  // const { message } = useErrorMessage();

  // const deleteVehicleClaim = async (id) => {
  //   try {
  //     await dispatchAPI('PATCH', {
  //       url: `${deleteUrl}/delete/${id}`
  //     });
  //   } catch (e) {
  //     message(e);
  //   }
  // };

  return [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Flex align="center" wrap="nowrap" justify="end" gap="small">
          <Link to={`/claims/${actionColumnUrl}/show/${record?._id}`}>
            <Tooltip title={t('breadcrumbs.show')}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Tooltip>
          </Link>
          <MessagingButton productClaim={record} />
          {/* {user.role === 'admins:SUPER-ADMIN' ||
          user.role === 'admins:ADMIN' ? (
            <>
              <MailingButton
                assetId={record?._id}
                templateName="Blank"
                tradKey="specific_demand"
                baseUrl="golfs"
              />
              <Tooltip title={t('light_vehicle.actions.delete')}>
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteVehicleClaim(record?._id)}
                  icon={<WarningOutlined />}
                >
                  <ClearOutlined style={{ fontSize: iconSize, color: 'red' }} />
                </Popconfirm>
              </Tooltip>
            </>
          ) : null} */}
        </Flex>
      )
    }
  ];
};
