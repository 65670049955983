/**
 * Formats a programme type to claim model.
 * @param {string} programmeType - The programme type to be formatted.
 * @returns {string} The formatted claim model.
 */
export const formatProgToClaimModel = (programmeType) => {
  if (programmeType !== 'FLEET') {
    const formattedProgrammeType =
      programmeType.charAt(0).toUpperCase() +
      programmeType.slice(1).toLowerCase();
    return `${formattedProgrammeType}_claim`;
  }
  return 'Vehicle_claim';
};
