import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Drawer, Row } from 'antd';
import React from 'react';
import { MessageWriterClaim } from '../messages/MessageWriterClaim';
import { Thread } from '../messages/Thread';
import { extractProgsAndSubProgrammes } from '../../utils/formatters/extractProgsAndSubProgrammes';

/**
 * Renders the ClaimConversation component.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Flag indicating whether the conversation is open or not.
 * @param {Object} props.selectedConversation - The selected conversation object.
 * @param {function} props.setOpen - The function to set the open state.
 * @param {function} props.patchToReadConversation - The function to patch the conversation to read.
 * @returns {JSX.Element|null} The rendered ClaimConversation component.
 */
const ClaimConversation = ({
  open,
  setOpen,
  selectedConversation,
  patchToReadConversation,
  productClaim,
  selectRefresh
}) => {
  if (!open) {
    return null;
  }

  const { t } = useTranslation();
  const { entry_id } = productClaim || {};
  const productName = extractProgsAndSubProgrammes(entry_id);

  const translatedProductName = t(`documents.form.${productName}`);

  const conversationType = 'claims';
  const onClose = async () => {
    await patchToReadConversation(selectedConversation._id, conversationType);
    setOpen({ conversation: false });
  };

  return (
    <Drawer
      title={`${translatedProductName} ${
        entry_id.unit_counter ? entry_id.unit_counter : ''
      }: ${productClaim.unit_counter ? productClaim.unit_counter : ''}`}
      placement="right"
      width={600}
      closable
      onClose={onClose}
      style={{ backgroundColor: '#FFFFFf' }}
      visible={open}
    >
      <Row style={{ width: '100%' }}>
        <Col span={24} className="messages-drawer">
          <Thread
            localSelectedConversation={selectedConversation}
            loading={false}
          />
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col
          span={24}
          className="new-message-col"
          style={{
            borderRadius: '5px'
          }}
        >
          <MessageWriterClaim
            url="/conversations"
            type="message"
            selectRefresh={selectRefresh || 'drawer'}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default ClaimConversation;

ClaimConversation.propTypes = {
  open: PropTypes.bool,
  selectedConversation: PropTypes.shape({
    _id: PropTypes.string
  }),
  setOpen: PropTypes.func.isRequired,
  patchToReadConversation: PropTypes.func,
  productClaim: PropTypes.shape({
    entry_id: PropTypes.shape({}),
    unit_counter: PropTypes.string
  }),
  selectRefresh: PropTypes.string
};

ClaimConversation.defaultProps = {
  open: false,
  selectedConversation: {},
  patchToReadConversation: () => {},
  productClaim: {},
  selectRefresh: null
};
