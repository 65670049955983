import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ModalSpecificDemand } from './ModalSpecificDemand';
import ClaimConversation from '../../../components/ClaimConversation';
import { useConversationContext } from '../../../../contexts/ConversationContext';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * Renders the Attestation buttons conditionally based on the render condition.
 * @component
 * @param {boolean} renderCondition - Whether to render the Attestation buttons.
 * @param {boolean} productClaim - Indicates if is on edit mode.
 * @param {function} resource - Custom function to manage changes.
 * @param {function} baseUrl - Vehicle data.
 * @param {function} reference - Custom function to manage changes.
 * @param {function} product - Vehicle data.
 * @returns {?React.Component} The rendered Attestation buttons component, or null if the render condition is false.
 */
const renderSpecificDemandConversationButton = (
  renderCondition,
  productClaim
) => {
  const initialState = { modal: false, conversation: false };
  const { t } = useTranslation();
  const tradKey = 'specific_demand_conversation';
  const [open, setOpen] = useState(initialState);
  const onCancel = () => setOpen(false);

  const {
    setSelectedConversation,
    selectedConversation,
    patchToReadConversation,
    refreshDrawer,
    setRefreshDrawer
  } = useConversationContext();

  const { dispatchAPI, company } = useAuthContext();

  const fetchConversation = async () => {
    const claimMessageUrl = `/conversations/claim?company=${company}&claim_id=${productClaim?._id}`;

    const { data } = await dispatchAPI('GET', { url: claimMessageUrl });

    const isEmptyObject = (obj) => Object.keys(obj).length === 0;
    if (isEmptyObject(data)) {
      return setOpen({ modal: true });
    }
    setSelectedConversation(data[0]);
    return setOpen({ conversation: true });
  };

  const onClick = async () => {
    await fetchConversation();
  };

  useEffect(() => {
    if (refreshDrawer) {
      fetchConversation();
      setRefreshDrawer(false);
    }
  }, [refreshDrawer]);

  if (renderCondition) {
    return (
      <>
        <Button onClick={onClick}>{`${t(`buttons.${tradKey}`)} `}</Button>
        <ModalSpecificDemand
          asset={productClaim}
          open={open.modal}
          onCancel={onCancel}
        />
        <ClaimConversation
          selectedConversation={selectedConversation}
          open={open.conversation}
          setOpen={setOpen}
          patchToReadConversation={patchToReadConversation}
          productClaim={productClaim}
        />
      </>
    );
  }
  return null;
};

export default renderSpecificDemandConversationButton;
