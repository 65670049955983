import { Row, Col } from 'antd';
import { useConversationContext } from '../../../contexts/ConversationContext';
import { ListConversations } from './ListConversations';
import { Thread } from '../Thread';
import { MessageWriter } from '../MessageWriter';
import { MessageWriterClaim } from '../MessageWriterClaim';

/**
 * @component
 * @name ConversationDisplay
 * @description A component that displays the list of conversations and the conversation thread
 * @param {string} filterKey The key to filter the conversations
 * @returns {jsx} jsx - ConversationDisplay component
 */
export const ConversationDisplay = () => {
  const { isThreadOpen, filter } = useConversationContext();

  return (
    <Row style={{ height: '100%' }}>
      <Col
        xs={24}
        md={12}
        xl={6}
        style={{ height: '100%' }}
        className="conversations-list"
      >
        <ListConversations />
      </Col>
      <Col
        style={{ height: '100%' }}
        xs={24}
        md={12}
        xl={18}
        className="conversation-col"
      >
        <Row style={{ height: '100%' }}>
          <Col
            style={{ height: '100%' }}
            xs={24}
            xl={12}
            className="messages-col"
          >
            {isThreadOpen && <Thread loading={false} />}
          </Col>
          <Col
            style={{ height: '100%' }}
            xs={24}
            xl={12}
            className="new-message-col"
          >
            {isThreadOpen && filter === 'collaborators' ? (
              <MessageWriter url="/conversations" type="message" />
            ) : null}
            {isThreadOpen && filter === 'claims' ? (
              <MessageWriterClaim
                url="/conversations"
                type="message"
                selectRefresh="messagePage"
              />
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
