/**
 * Extracts the programme types and sub-programmes from the given data object.
 * If any sub-programme is found, it returns the first sub-programme, otherwise it returns the programme types.
 *
 * @function
 * @param {object} data - The data object containing programme types and sub-programmes.
 * @returns {string} - The extracted sub-programme or programme types.
 */
export const extractProgsAndSubProgrammes = (data) => {
  const {
    programme_types,
    fleet_type,
    civil_liability_sub_programmes,
    transport_sub_programmes,
    construction_sub_programmes
  } = data || {};

  const subProgrammesParts = [
    fleet_type,
    civil_liability_sub_programmes,
    transport_sub_programmes,
    construction_sub_programmes
  ];

  const isSubProgramme = subProgrammesParts.filter(
    (part) => typeof part === 'string'
  );
  if (isSubProgramme.length > 0) {
    return isSubProgramme[0];
  }
  return programme_types;
};
